import React from "react";
import "./styles.scss";
import { data } from "./data";
import MasonaryCard from "./MasonaryCard";

const Feeds = () => {
  return (
    <div className="feed">
      <div className="feed-title">
        Building a tribe of{" "}
        <span className="feed-subtitle">future sales and marketing leaders</span>
      </div>
      <div className="sub-head">Here’s what they have to say</div>
      <div className="gradient-bg"></div>
      <div className="card-holder">
        {data.map((items, i) => {
          return (
            <div>
              {items.map((item, index) => (
                <MasonaryCard key={index + "_" + i} data={item} />
              ))}
            </div>
          );
        })}
        {/* <div className="shadow"></div> */}
      </div>
    </div>
  );
};

export default Feeds;
