import React from "react";

import "./styles.scss";

const KraftshalaWorks = () => {
  return (
    <div className="home-kraftshala-works">
      <div className="home-works-block">
        <div className="title">Want to know more?</div>
        <a href="/about/"  className="works-link">
        About Us
        </a>
      </div>
      <div className="home-works-block">
        <div className="title">Want to get ahead in career?</div>
        <a href="/blog/"  className="works-link" >
        Read Our Blogs
        </a>
      </div>
      <div className="home-works-block">
        <div className="title">Want to get familiar with marketing?</div>
        <a href="/marketing-glossary/" className="works-link">
        Marketing Glossary
        </a>
      </div>
    </div>
  );
};

export default KraftshalaWorks;
