import React from "react";
import Slider from "react-slick";
import Card8 from "../../TestimonialCard/Card8/index";
import { courseData } from "./data";

import "./styles.scss";
import { useLocation } from "@reach/router";

function AlumniCarousal() {

  const location = useLocation();

  const settings = {
    prevArrow: "",
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    // fadeIn: true,
    autoplay: true,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          //variableWidth: false,
          infinite: true,
          //centerMode: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="home-pick-slider mlp-main">
        <div className="placements-container">
          <div className="placement-slider" id="slider-main">

            <Slider {...settings}>
              {courseData.map((info, key) => (
                <a href={info.link + location?.search} >
                  <Card8
                    key={key}
                    // image={info.image}
                    course = {info.course}
                    image= {info.image}
                    imagehover= {info.imagehover}
                    title = {info.title}
                    startAs = {info.startAs}
                    link = {info.link + location?.search}
                  />
                
                </a>
              ))}
            </Slider>
          </div>
          <div className="specialization-footer">
          Not sure which course is right for you? We’ll help you take the first step.
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AlumniCarousal;
