import React, { Fragment } from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./styles.scss";
import AlumniCarousal from "./AlumniCarousal";
import { Link, useLocation } from "@reach/router";

const HeroSection = () => {

  const location = useLocation();

  return (
    <Fragment>
      <div className="hero-container">
        <div className="hero-heading">
        EDUCATION <span className="mobile-block">THAT GETS YOU HIRED</span>
        </div>
        <div className="title">
          An Online School where you graduate with a job, and not just a degree or a certificate.        
        </div>
        <StaticImage
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1otvnj0.webp"
          alt="underline"
          className="yellow-underline"
        />
        <div className="data-sec">
          <div className="data">
            <div className="value">94%</div>
            <StaticImage
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1otn6pl.webp"
              alt="underline"
              className="grey-underline"
            />
            Placement rate
          </div>
          <div className="data">
            <div className="hrLine"></div>
          </div>
          <div className="data">
            <div className="value">
              1800+ 
            </div>
            <StaticImage
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1otn6pl.webp"
              alt="underline"
              className="grey-underline"
            />
            Students Placed
          </div>
          <div className="data">
            <div className="hrLine"></div>
          </div>
          <div className="data">
            <div className="value">
              400+
            </div>
            <StaticImage
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1otn6pl.webp"
              alt="underline"
              className="grey-underline"
            />
            Recruiting partners
          </div>
        </div>
        
        <Link className="apply" to={`${location?.search}#home-pick-program`}>Know More</Link>
        

        <div className="bg">
          <div className="content mobile-hide">
            <div className="content-header">
              They came from different fields and left as
            </div>
            <div className="content-subheader">kick-ass professionals!</div>
          </div>
          <div className="content pc-hide">
            <div className="content-header">
              They came from different fields and left as{" "}
              <span className="content-subheader">kick-ass professionals!</span>
            </div>
          </div>
          <AlumniCarousal />
        </div>
      </div>
    </Fragment>
  );
};

export default HeroSection;
