

export const Data = [{
  name : 'Prachi Kothiyal',
  field: 'Electrical Engineer turned Marketer',
  company: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21nqqs7.webp",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35pob5y.webp"
  ,
  description: 'Couldn’t convince my parents why I wanted this course, but they saw me change during the course. The learning from here does not end at placements, it goes way beyond that.'
},
{
  name : 'Atul Singh',
  field: 'From BBA to Content Writing',
  company: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21nn523.webp",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21i5rwf.webp",
  description: 'Human Skills was worth it. It helped me not only during the course but also now in the job while working in a team and respecting everyone’s opinions.'
},
{
  name : 'Gazal Chawla',
  field: 'Student turned Digital Marketer',
  company: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21l0eie.webp",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21iziba.webp",
  description: 'I didn’t mind sitting throughout the day during the course because I loved what I did. It was rather a fun journey for me because of my batchmates!'
},
{
  name : 'Sourav Patra',
  field: 'Fashion influencer to handling 14 brands',
  company: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21oui53.webp",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21j71v7.webp",
  description: 'I met my tribe here who helped me learn, grow and stay motivated throughout the course. And guess what that led to? Cracked a job in my first interview!'
},
{
  name : 'Charu Kulsreshtha',
  field: 'From Cabin Crew to Marketing',
  company: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21n9pty.webp",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21i7z76.webp",
  description: 'The training here was so much more practical and immersive than my aviation journey. Connecting with teammates, experts and getting feedback helped me get better.'
},
{
  name : 'Kanika Kashyap',
  field: 'From Hotel Management to Digital Marketing',
  company: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21oru0u.webp",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21j1t04.webp",
  description: 'Apart from the course being extensive that required me to put in a lot of effort, human skills helped me gain more confidence to perform and helped me present my CV better.'
},
{
  name : 'Dhara Kaji',
  field: 'From 0 experience to dream job',
  company: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21olfue.webp",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21iacqs.webp",
  description: 'Such a rich learning experience. Not just that, the course gave me an opportunity to be independent and do things on my own, exactly how I wanted!'
},
{
  name : 'Nitish Sonu',
  field: 'From Btech to Content Writing',
  company:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21owm01.webp",
  image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21j40rl.webp",
  description: 'Human skills sessions have enabled me to deal with people in a way where from being a reserved person to now I do not think twice before getting out of my way to get things done. '
},
{
  name : 'Nilta Sood',
  field: 'CA turned Digital Marketer',
  company: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21oa6e1.webp",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y48n5m35px2v4.webp"
  ,
  description: 'The three months here made me so disciplined and ambitious about my career which 2020 Nilta couldn’t have even imagined herself to be. '
},
{
  name : 'Dhruv Patel',
  field: 'Computer Engineering to Client Servicing',
  company: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21ohypm.webp",
  image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21isxkh.webp",
  description: 'We had incredible expert sessions with super-experienced people that helped me gain a lot of new perspectives. Can’t thank them enough!'
},

];