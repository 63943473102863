

export const courseData = [
  {
    course: 'PGP in Sales and Business Leadership',
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21jwmcd.webp",
    imagehover:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21k05zv.webp",
    title: 'Start your business leadership career in top companies in high potential roles of 7.5-17LPA. If the job < 7.5L, you get a 60% refund!',
    startAs:['Sales Managers / Executives','Business Development Executives','Key Account Managers / Executives'],
    link: '/sales-course/',
  },
  {
    course: 'Content And Social Media',
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21jsz08.webp",
    imagehover:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21jv81n.webp",
    title: 'The place to get started in the Creative Marketing Industry! Create memes for brands, write blogs and get exposed to building a brand through content.',
    startAs:['Social Media Marketer','Content Writer', 'Influencer Marketing & more'],
    link: '/content-writing-course/',
  },
  {
    course: 'Digital Media Marketing',
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21kb9c3.webp",
    imagehover:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21kdlch.webp",
    title: 'Want to drive business results? Learn to run digital ads, create SEO strategies, manage E-com listings, and analytics, all to eventually drive revenue for great brands',
    startAs:['Digital Marketing Associate','Performance Marketer','SEO Executive','E-commerce Analyst & more'],
    link: '/marketing-launchpad/digital-marketing-course/',
  }
];
