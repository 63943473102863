import React from "react";
import AlumniCarousal from "./AlumniCarousal";
import "./styles.scss";

const PickProgram = ({ search }) => {
  return (
    <div className="home-pick-program" id="home-pick-program">
      <div className="pick">PICK A COURSE</div>
      <div className="title">
      Each of them eventually leads to
      </div>
      <div className="sub-title">sales and marketing leadership roles</div>

      <AlumniCarousal />

    </div>
  );
};

export default PickProgram;
