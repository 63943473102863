import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";
import Marquee from "react-fast-marquee";
import LazyLoad from "react-lazyload";

export default function TopCompanies() {
  return (
    <div className="home-top-companies">
      <div className="mobile-hide">
        <div className="title">
        In the last 12 months, our students have been hired by the
        </div>
        <div className="sub-title">world’s best sales and marketing companies</div>
      </div>
      <div className="pc-hide">
        <div className="title">
        In the last 12 months, our students have been hired by the{" "}
          <span className="sub-title">world’s best sales and marketing companies</span>
        </div>
      </div>
      <LazyLoad>
        <div className="mobile-hide">
          <Marquee className="marquee">
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ou1woi.webp"
              alt="growisto"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ov27bv.webp"
              alt="atomberg"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovfgzs.webp"
              alt="atomberg"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ov4dtz.webp"
              alt="beacon"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ov5tk5.webp"
              alt="sesa"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ov84hm.webp"

              alt="scaler"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovc6yn.webp"
              alt="phisics"
            />
           
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovjr64.webp"
              alt="schbang"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovogca.webp"
              alt="coursera"
            />
          </Marquee>
          <Marquee className="marquee2">
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovuzjv.webp"
              alt="social"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovy1c4.webp"
              alt="toothisi"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovzvnx.webp"
              alt="neeman"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ow3s10.webp"
              alt="nyka"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ow5u7s.webp"
              alt="masai"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ow9eaj.webp"
              alt="tata"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1owch7z.webp"
              alt="groupm"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1owivqq.webp"
              alt="performatic"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ownx24.webp"
              alt="ant"
            />
         
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1owratj.webp"
              alt="pumpkin"
            />
            {/* <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21ntx87.webp"
              alt="publicis"
            /> */}
          </Marquee>
        </div>
      </LazyLoad>
      <LazyLoad>
        <div className="pc-none">
          <Marquee>
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ou1woi.webp"
              alt="growisto"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ov27bv.webp"
              alt="atomberg"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ov4dtz.webp"
              alt="beacon"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ov5tk5.webp"
              alt="sesa"
            />
          </Marquee>
          <Marquee>
              
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ov84hm.webp"
              alt="scaler"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovc6yn.webp"
              alt="phisics"
            />
       
     
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovjr64.webp"
              alt="schbang"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovogca.webp"
              alt="coursera"
            />
          </Marquee>
          <Marquee>
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovuzjv.webp"
              alt="social"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovy1c4.webp"
              alt="toothisi"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ovzvnx.webp"
              alt="neeman"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ow3s10.webp"
              alt="nyka"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ow5u7s.webp"
              alt="masai"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ow9eaj.webp"
              alt="tata"
            />
          </Marquee>
          <Marquee>
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1owch7z.webp"
              alt="groupm"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1owivqq.webp"
              alt="performatic"
            />
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ownx24.webp"
              alt="ant"
            />
    
            <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1owratj.webp"
              alt="pumpkin"
            />
            
            {/* <StaticImage
              className="company"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21ntx87.webp"
              alt="publicis"
            /> */}
          </Marquee>
        </div>
      </LazyLoad>
    </div>
  );
}
