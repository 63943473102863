import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";

const NewsCoverage = () => {
  return (
    <React.Fragment>
      <div className="newsCoverage">
        <div className="news-title">
        News coverage
        </div>
        <StaticImage
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1q7bs19.webp"
          alt="underline"
          className="yellow-underline"
        />

        <div className="img-container">
          <a href="https://www.thehindu.com/education/information-on-admissions-new-courses-scholarships-mous-partnerships-and-more/article35758777.ece" target='_blank'>
            <StaticImage
              className="news-img"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1q7ezum.webp"
              alt="The hindu"
            />
          </a>
          {/* <a href="http://bweducation.businessworld.in/article/Kraftshala-Marketing-Launchpad-program/09-08-2021-400073/" target='_blank'>
            <StaticImage
              className="news-img"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21n5q2i.webp"
              alt="BWeducation"
            />
          </a> */}
          <a href="https://www.educationtimes.com/article/editors-pick/85682491/importance-of-marketing-in-executive-education" target='_blank'>
            <StaticImage
              className="news-img"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1q7ksin.webp"
              alt="education times"
            />
          </a>
          <a href="https://www.highereducationdigest.com/how-marketing-as-a-subject-has-evolved-over-the-years-gaining-momentum-as-it-progresses/" target='_blank'>
          
            <StaticImage
              className="news-img"
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1q7pjoq.webp"
              alt="higher education digest" 
            />
          </a>
        </div>
        <div className="news-title">
        And we’re just getting <span className="sub-title">started…</span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewsCoverage;
