import React from "react";
import "./styles.scss";

function HomePageCard({ image, name, company, description,field }) {
  return (
    <>
      <div className="card-container">
        <div className="beforeHover">
          <div className="name">{name}</div>
          <div className="field">{field}</div>
          <img className="company" src={company} loading="lazy"/>
          <img src={image} className='profile' alt={name} loading="lazy"/>
        </div>
        <div class="hover">
          <div className="name">{name}</div>
          <div class="text">{description}</div>
        </div>
      </div>
    </>
  );
}

export default HomePageCard;
