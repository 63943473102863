import React from "react";
import Slider from "react-slick";
import HomePageCard from "../../TestimonialCard/HomePageCard/index";
import { Data } from "./data";
import "./styles.scss";

function AlumniCarousal() {

  const settings = {
    prevArrow: "",
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="home-cards mlp-main">
        <div className="placements-container">
          <div className="placement-slider">

            <Slider {...settings}>
              {Data.map((info, key) => (
                <HomePageCard
                  key={key}
                  image={info.image}
                  name={info.name}
                  company={info.company}
                  description={info.description}
                  field={info.field}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AlumniCarousal;
