export const data = [
  [
    {
      video: "https://www.youtube.com/embed/OHLoEUKu1O0",
    },

    {
      name: "Rohit Baskar",
      position: "Programmatic Intern @ Ethinos",
      linkedin:
        "https://www.linkedin.com/posts/rohit-baskar-3a7459140_digitalmarketing-marketing-activity-6991619204596596736-Y_GW?utm_source=share&utm_medium=member_android",
      about: `Months back,I said to my friend that I'm interested in marketing. Probably, I might do a digital marketing course. <br/><br/> That guy suggested me an online platform called <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" >Kraftshala.</a><br/> Joined the course by clearing the entrance exam without thinking about what other online platforms <a target="_blank" href="https://www.linkedin.com/posts/rohit-baskar-3a7459140_digitalmarketing-marketing-activity-6991619204596596736-Y_GW?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
    {
      name: "Trisha Gupta",
      position: "SEO Analyst @ Performics",
      linkedin:
        "https://www.linkedin.com/posts/guptatrisha_i-am-very-grateful-to-god-and-excited-to-activity-6995092601301307392-lXPq?utm_source=share&utm_medium=member_android",
      about: `I am very grateful to God and excited to announce that I have started working as an SEO analyst at <a target="_blank" class="page-links" href="https://www.linkedin.com/company/performics-india/" > Performics India</a> (Publicis Groupe).
    <br/><br/>
  I want to thank <a target="_blank" class="page-links" href="https://www.linkedin.com/in/ACoAAACiJ58B2X6YGH4z5rp2DifhlUjzkApbEPM" >Varun Satia </a>and Eshu Sharma for giving me this wonderful opportunity at Kraftshala and making me believe that anything is possible if you aspire to do it. I want to thank my SST Monica and the entire <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" > Kraftshala</a> team who had always helped me… <a target="_blank" href="https://www.linkedin.com/posts/guptatrisha_i-am-very-grateful-to-god-and-excited-to-activity-6995092601301307392-lXPq?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
  ],
  [
   
    {
      name: "Prince Chourasiya",
      position: "Performance Marketing @ MediaMint",
      linkedin:
        "https://www.linkedin.com/posts/prince-chourasiya-385042228_firstjob-career-performancemarketing-activity-6998442519051063296-diuu?utm_source=share&utm_medium=member_android",
      about: `I'm delighted to inform you that I have joined <a target="_blank" class="page-links" href="https://www.linkedin.com/company/mediamint/" > MediaMint </a>as a Performance Marketing Associate.
    <br/><br/>
First and foremost, I'd like to express my gratitude to <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" >Kraftshala </a> for providing a opportunity for us to learn about Performance Marketing and behavioral skill <a target="_blank" href="https://www.linkedin.com/posts/prince-chourasiya-385042228_firstjob-career-performancemarketing-activity-6998442519051063296-diuu?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
    {
      video: "https://www.youtube.com/embed/Pm2SMH3RFVE",
    },
    {
      name: "Nikhil Attri",
      position: "Junior Copywriter @ CupShup",
      linkedin:
        "https://www.linkedin.com/posts/nikhil-attri-a1a5ba18a_living-faith-kraftshala-activity-6997990142003064832-43xp?utm_source=share&utm_medium=member_android",
      about: `Were the assignments easy?  <br/>
They were exhaustive; we used to stay till late and again join back at 9 in the morning. Last-minute deadlines and major panic!
<br/><br/>
But the Amazing teachers <a target="_blank" href="https://www.linkedin.com/posts/nikhil-attri-a1a5ba18a_living-faith-kraftshala-activity-6997990142003064832-43xp?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
  ],
  [
    {
      video: "https://www.youtube.com/embed/z0QKEzLdoqA",
    },
    

    {
      name: "Devanshu Goyal",
      position: "Programmatic Analyst @ Performics",
      linkedin:
        "https://www.linkedin.com/posts/devanshu-goyal-5027b1164_im-excited-to-share-that-i-have-started-activity-7003059736178749440-SO5G?utm_source=share&utm_medium=member_android",
      about: `I'm excited to share that I have started working as a programmatic analyst in <a target="_blank" class="page-links" href="https://www.linkedin.com/company/performics-india/" >Performics India</a>.
    <br/><br/>
I want to thank <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" > Kraftshala</a> for all the knowledge and support from start to end.  <br/>
It was an exciting journey which was not possible without <a target="_blank" href="https://www.linkedin.com/posts/devanshu-goyal-5027b1164_im-excited-to-share-that-i-have-started-activity-7003059736178749440-SO5G?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
    {
      video: "https://www.youtube.com/embed/a8dlulnzvfI",
    },
  ],
  [
    {
      name: "Srishti Agnihotri",
      position: "Content Marketer @ Skyline ",
      linkedin:
        "https://www.linkedin.com/posts/srishti-agnihotri-a1b1aa4b_marketing-job-learning-activity-6942023610312077312-E9Up?utm_source=share&utm_medium=member_android",
      about: `The journey of becoming a Content Marketer from a System Engineer has been mad and marvellous. I want to thank the people at <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" > Kraftshala </a> for making it possible. After 12 weeks of tiresome training, never-ending assignments, exciting expert sessions, learning tedious marketing module <a target="_blank" href="https://www.linkedin.com/posts/srishti-agnihotri-a1b1aa4b_marketing-job-learning-activity-6942023610312077312-E9Up?utm_source=share&utm_medium=member_android">...read more</a>`,
    },

    {
      name: "Ankit Tiwari",
      position: "Trainee Analyst @ Publicis",
      linkedin:
        "https://www.linkedin.com/posts/ankit-tiwari-kb99_thankyou-grateful-development-activity-7014978002321043456-6ivq?utm_source=share&utm_medium=member_android",
      about: `I'm excited to announce that I have recently obtained a new certification: the Industrycreds Certificate from <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" >Kraftshala</a>! This program has provided me with valuable skills and knowledge in my industry, and I am thrilled to have the opportunity to showcase <a target="_blank" href="https://www.linkedin.com/posts/ankit-tiwari-kb99_thankyou-grateful-development-activity-7014978002321043456-6ivq?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
    {
      name: "Yash Kumar",
      position: "Business Strategy @ ET Medialabs",
      linkedin:
        "https://www.linkedin.com/posts/yash-kumar-05693b1b4_im-happy-to-share-that-im-starting-a-new-activity-7010898553115963393-nNQy?utm_source=share&utm_medium=member_android",
      about: `I’m happy to share that I’m starting a new position as Associate- Business Strategy & Analyst at <a target="_blank" class="page-links" href="https://www.linkedin.com/company/et-medialabs/" >ET Medialabs</a>!
    <br/><br/>
    I really want to thank <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" >Kraftshala</a> team, coaches and my peer <a target="_blank" href="https://www.linkedin.com/posts/yash-kumar-05693b1b4_im-happy-to-share-that-im-starting-a-new-activity-7010898553115963393-nNQy?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
  ],
  [
    {
      name: "Digvijay Salunkhe",
      position: "Digital Marketing Associate @ MediaMint",
      linkedin:
        "https://www.linkedin.com/posts/digvijay-salunkhe_mediamint-marketing-advertising-activity-7018912219337822208-bPNs?utm_source=share&utm_medium=member_android",
      about: `I am excited to announce that I am starting a new position at MediaMint as a digital marketing associate! I am thrilled to join such a talented and innovative team at this dynamic media and marketing company.
    <br/><br/>
    Over the past 5 months, I was part of <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" >Kraftshala</a> I <a target="_blank" href="https://www.linkedin.com/posts/digvijay-salunkhe_mediamint-marketing-advertising-activity-7018912219337822208-bPNs?utm_source=share&utm_medium=member_android">...read more</a>`,
    },

    {
      video: "https://www.youtube.com/embed/yhJGG5-LO5E",
    },
    {
      name: "Sourav Patra",
      position: "Copywriter @ White Grape",
      linkedin:
        "https://www.linkedin.com/posts/sourav-patra-909b44188_marketing-career-connections-activity-6985964630154108928-7y0e?utm_source=share&utm_medium=member_android",
      about: `Hello LinkedIn family,  <br/>
    I am thrilled to announce that I would be starting a new position as well as my first job as a Copywriter at White Grape Communications Pvt. Ltd
    <br/><br/>
   Extremely grateful to Kraftshala Varun Satia and Eshu Sharma for creating such a wonderful fast paced program which <a target="_blank" href="https://www.linkedin.com/posts/sourav-patra-909b44188_marketing-career-connections-activity-6985964630154108928-7y0e?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
  ],

  [
    {
      name: "Narendar Singh",
      position: "SEM Executive @ AGL Hakuhodo",
      linkedin:
        "https://www.linkedin.com/posts/narender-singh-5816b915a_newjob-marketing-thankyou-activity-6978965025784774656-4nSN?utm_source=share&utm_medium=member_android",
      about: `I am thrilled to announce that I have joined <a target="_blank" class="page-links" href="https://www.linkedin.com/company/agl-hakuhodo/" >AdGlobal360</a> as a Search Engine Marketing Executive!
    <br/><br/>
    I would love to thank <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" >Kraftshala</a> and their entire team for all the training and for providing me with this great opportunity <a target="_blank" href="https://www.linkedin.com/posts/narender-singh-5816b915a_newjob-marketing-thankyou-activity-6978965025784774656-4nSN?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
    
    {
      name: "Harshvardhan Singh Chauhan",
      position: "Digital Media Planning Intern @ Omnicom",
      linkedin:
        "https://www.linkedin.com/posts/harshvardhan-singh-chauhan-8b659493_newjob-digitalamarketing-activity-6996868988152500225-46iZ?utm_source=share&utm_medium=member_android",
      about: `I Was waiting for this day since so long and of course it's 11:11 Today and I'm thrilled to start my new role as Digital Media Planning intern at <a target="_blank" class="page-links" href="https://www.linkedin.com/company/omnicom-media-group_2/" >Omnicom Media Group</a>!  <br/>
    Thank you, <a target="_blank" class="page-links" href="https://www.linkedin.com/company/learn-kraftshala/" >Kraftshala</a>, for helping me in both my learning <a target="_blank" href="https://www.linkedin.com/posts/harshvardhan-singh-chauhan-8b659493_newjob-digitalamarketing-activity-6996868988152500225-46iZ?utm_source=share&utm_medium=member_android">...read more</a>`,
    },
    {
      video: "https://www.youtube.com/embed/R9NwWCjAJ-M",
    },
  ],
];
