import React from "react";
import "./styles.scss";

function Card8({ course, image, imagehover, title, startAs, link }) {
  return (
    <div className="card-8">
      <div className="body">
        <div className="icons">
          {/* <StaticImage src="../../../images/home/bg.png" alt="bg" /> */}
          <img src={image} alt={course} className='normal'/> 
          <img src={imagehover} alt={course} className='onhover'/> 
        </div>
        <div className="course">{course}</div>
        <div className="card-description">{title}</div>
        <div className="start-as-title">You start as</div>
        {startAs.map((e) => {
          return <div className="startAs">{e}</div>;
        })}
      </div>
      
      <a href={link} target="_blank" alt={title} className="course-read-more">
        Apply now
      </a>
    </div>
  );
}

export default Card8;
