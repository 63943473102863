import React from "react";

import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";

const StudentsBridgeGap = () => {
  return (
    <React.Fragment>
      <div className="home-students-bridge-gap">
        <div className="mobile-hide">

          <div className="title">Differentiating our students through</div>
          <div className="sub-title">intensity and practicality</div>
        </div>

        <div className="pc-hide">

          <div className="title">Differentiating our students through{" "}
            <span className="sub-title">intensity and practicality</span>
          </div>
        </div>

        <div className="home-card-container">
          <div className="card">
            <div className="icon">
              <div className="bg-box">
                <StaticImage
                  className="icon-img"
                  src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1owzsw2.webp"
                  alt="icon"
                />
              </div>
            </div>
            <div className="content">
              <div className="head">3-4 hours with experts everyday</div>
              <div className="body">
                Out of 10 hours of training everyday, you spend 3-4 hours with industry experts who’ve 
                worked as sales and marketing leaders at companies like Nestle, HUL, Performics, etc.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="icon">
              <div className="bg-box">
                <StaticImage
                  className="icon-img"
                  src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ox0wqv.webp"
                  alt="icon"
                />
              </div>
            </div>
            <div className="content">
              <div className="head">Over 100+ assignments</div>
              <div className="body">
                1 million assignments and mock interviews later :p, you’ll solve
                business problems in your sleep. Get
                <b> ready to crack any and every job interview.</b>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="icon">
              <div className="bg-box">
                <StaticImage
                  className="icon-img"
                  src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ox1t2u.webp"
                  alt="icon"
                />
              </div>
            </div>
            <div className="content">
              <div className="head">300+ hours on actual brand problems</div>
              <div className="body">
                Spend 3-4 hours everyday working on actual business problems. 
                No Xerox copies to mug up from. Only real-world business skills through case studies across sectors.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="icon">
              <div className="bg-box">
                <StaticImage
                  className="icon-img"
                  src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ox2lie.webp"
                  alt="icon"
                />
              </div>
            </div>
            <div className="content">
              <div className="head">140+ hours with Human Skills coaches</div>
              <div className="body">
                You learn skills that'll help you not just to get the job, 
                but also ace it and become a future sales and marketing leader. 
                Get prepared for any challenge your job throws at you.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StudentsBridgeGap;
